import React, {createContext, useState, useEffect} from "react";
import {HandySvg} from "handy-svg";
import ImageUtils from "@components/imageUtils";
import "./NotificationContext.css";

const images = ImageUtils.importAllImages(require.context("@assets/context/notification", false, /\.(svg)$/));
export const NotificationContext = createContext();

const labelNotification = {
  error: "Ошибка",
  success: "Успех",
  info: "Инфо",
  warning: "Внимание",
};

export const NotificationProvider = ({children}) => {
  const [notificationType, setNotificationType] = useState("");
  const [notificationText, setNotificationText] = useState("");
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [timer, setTimer] = useState(null);

  const openNotification = (content, type) => {
    setNotificationType(type);
    setNotificationText(content);
    setIsNotificationOpen(true);
    setIsClosing(false);

    if (timer) clearTimeout(timer);

    const newTimer = setTimeout(() => {
      closeNotificationWithAnimation();
    }, 3000);

    setTimer(newTimer);
  };

  const closeNotificationWithAnimation = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsNotificationOpen(false);
    }, 300); // Время анимации
  };

  const closeNotification = () => {
    clearTimeout(timer);
    closeNotificationWithAnimation();
  };


  useEffect(() => {
    const handleMouseDown = (e) => {
      if (e.target.closest('.notification-window')) {
        e.preventDefault()
      }
    };

    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  const showError = (content) => openNotification(content, "error");
  const showWarning = (content) => openNotification(content, "warning");
  const showInfo = (content) => openNotification(content, "info");
  const showSuccess = (content) => openNotification(content, "success");

  const handleMouseEnter = () => {
    if (timer) clearTimeout(timer);
  };

  const handleMouseLeave = () => {
    const newTimer = setTimeout(() => {
      closeNotificationWithAnimation();
    }, 5000);

    setTimer(newTimer);
  };

  useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [timer]);

  return (
    <NotificationContext.Provider
      value={{openNotification, closeNotification, showError, showWarning, showSuccess, showInfo}}
    >
      {children}
      {isNotificationOpen && (
        <div
          className={`notification-window ${notificationType} ${isClosing ? "closing" : ""}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="notification-window--content">
            <HandySvg src={images[`${notificationType}.svg`]} className="currentColor mr-5"/>
            <b className="mr-5">{labelNotification[notificationType]}: </b>
            {notificationText}
          </div>
          <HandySvg src={images["close.svg"]} className="notification-window--close mr-5" onClick={closeNotification}/>
        </div>
      )}
    </NotificationContext.Provider>
  );
};
