import React, {useContext} from 'react';
import {AuthContext} from "@context/AuthContext";
import './ResourcesSection.css';

import ImageUtils from '@components/imageUtils';

const images = ImageUtils.importAllImages(require.context('@assets/profile', false, /\.(svg)$/));

// Оптимизированный DetailItem
const DetailItem = React.memo(({logo, title}) => (
  <div className="detail-item">
    <img
      src={images[`${logo}.svg`]}
      alt="logo"
      draggable="false" // Запрещаем перетаскивание
    />
    {title}
    <img
      src={images[`plus.svg`]}
      alt="logo"
      draggable="false" // Запрещаем перетаскивание
      style={{marginLeft: 'auto'}}
    />
  </div>
));

const ResourcesSection = React.memo(() => {
  const {userInfo} = useContext(AuthContext);

  return (<section>
    <div className="resources-text">
      <div className="banner-label">Мои ресурсы</div>
      <h2>Узнайте подробнее о ваших ресурсах</h2>
      <div>У вас осталось:</div>
      <div className="resources-details">
        <DetailItem
          logo="bots"
          title={`${userInfo.bot_count} ботов`}
        />
        <DetailItem
          logo="channels"
          title={`5 каналов`}
        />
        <DetailItem
          logo="task"
          title={`${userInfo.daily_task_limit} задач`}
        />
        <DetailItem
          logo="autotask"
          title={`6 автозадач`}
        />
      </div>
    </div>
    <img
      src={images['chuvachki2.svg']}
      alt="logo"
      className="chuvachki"
      draggable="false" // Запрещаем перетаскивание
    />
  </section>)
});

export default ResourcesSection;
