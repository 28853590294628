import React, { useEffect, useContext } from 'react';
import { AuthContext } from '@context/AuthContext';
import LoginForm from './LoginForm';
import LoginInfoBlock from './LoginInfoBlock';
import './Login.css';

const Login = () => {
  const { handleClose } = useContext(AuthContext);

  useEffect(() => {
    const handleMouseDown = (e) => {
      if (!e.target.closest('.auth-window') && !e.target.closest('.notification-window')) {
        handleClose();
      }
    };

    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleClose]);
  
  useEffect(() => {
    document.body.classList.add('no-scroll'); // Блокируем прокрутку страницы
    return () => {
      document.body.classList.remove('no-scroll'); // Разрешаем прокрутку страницы
    };
  }, []);

  return (
    <div className="auth-window-overlay">
      <div className="auth-window">
        <div className="auth-content">
          <LoginForm />
          <LoginInfoBlock />
        </div>
      </div>
    </div>
  );
};

export default Login;
