import React, {useState, useContext, useEffect} from 'react';
import {sendEmailCode} from '@components/shared/api';
import InputField from '@components/shared/InputField';
import { AuthContext } from '@context/AuthContext';
import {NotificationContext} from "@context/Notification/NotificationContext";

import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/auth', false, /\.(svg)$/));

export default function RegistrationForm({
                                           handleCodeStep,
                                           email, setEmail,
                                           name, setName,
                                           channel, setChannel,
                                           password, setPassword,
                                           confirmPassword, setConfirmPassword,
                                           temp_token
                                         }) {
  const {handleClose, handleLogin} = useContext(AuthContext);
  const {showError} = useContext(NotificationContext);

  const [errors, setErrors] = useState({
    email: '',
    name: '',
    channel: '',
    password: '',
    confirmPassword: '',
  });

  useEffect(() => {
    setErrors({
      email: '',
      name: '',
      channel: '',
      password: '',
      confirmPassword: '',
    });
  }, [email, name, channel, password, confirmPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Регулярное выражение для проверки email
    const emailRegex = /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;

    const newErrors = {
      email: emailRegex.test(email) ? '' : 'Введите корректный email.',
      name: name.trim().length > 0 ? '' : 'Имя не должно быть пустым.',
      channel: channel.trim().length > 0 ? '' : 'Введите ваш профиль (например, @toup).',
      password: password.trim().length >= 6 ? '' : 'Пароль должен быть не менее 6 символов.',
      confirmPassword: password === confirmPassword ? '' : 'Пароли не совпадают.',
    };

    setErrors(newErrors);

    // Если есть ошибки, прерываем выполнение
    const firstError = Object.values(newErrors).find((error) => error);
    if (firstError) {
      showError(firstError); // Показать сообщение о первой ошибке
      return;
    }

    try {
      const response = await sendEmailCode(temp_token, email);
      if (response.status === 200) {
        handleCodeStep();
      } else {
        showError(response?.data?.msg || 'Ошибка при отправке кода.');
      }
    } catch (err) {
      console.error(err);
      showError('Ошибка при регистрации.');
    }
  };

  return (
    <div className="auth-form">
      <div className='label'>
        <div>
          <p className="text-blue-left">Создайте аккаунт</p>
          <h2>ДОБРО ПОЖАЛОВАТЬ в ToUP</h2>
        </div>
        <img src={images['close.svg']} alt='close' onClick={handleClose}/>
      </div>
      <form onSubmit={handleSubmit}>
        <InputField
          label="Email"
          type="text"
          placeholder="Ваша почта"
          logo={images['mail.svg']}
          error={!!errors.email}
          value={email}
          handleChange={(e) => setEmail(e.target.value)}
        />
        <div className="registration-email-channel-container">
          <InputField
            label="Имя"
            type="text"
            placeholder="Напишите имя"
            logo={images['login.svg']}
            error={!!errors.name}
            value={name}
            handleChange={(e) => setName(e.target.value)}
          />
          <InputField
            label="Профиль"
            type="text"
            placeholder="Ваш Telegram"
            logo={images['telegram.svg']}
            error={!!errors.channel}
            value={channel}
            handleChange={(e) => setChannel(e.target.value)}
          />
        </div>
        <InputField
          label="Пароль"
          type="password"
          placeholder="Придумайте пароль"
          logo={images['password.svg']}
          error={!!errors.password}
          value={password}
          handleChange={(e) => setPassword(e.target.value)}
        />
        <InputField
          label="Повторите пароль"
          type="password"
          placeholder="Повторите пароль"
          logo={images['password.svg']}
          error={!!errors.confirmPassword}
          value={confirmPassword}
          handleChange={(e) => setConfirmPassword(e.target.value)}
        />
        <button type="submit" style={{marginTop: '65px'}}>Создать аккаунт</button>
      </form>
      <p className='switch-auth-window'>Уже есть аккаунт? <div onClick={handleLogin}>Войти</div></p>
    </div>
  );
}
