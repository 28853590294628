import React, { useState, useContext, useEffect } from 'react';
import { TaskContext } from '@components/Tasks/AddTaskWindow/TaskContext';
import InputField from '../../../shared/InputField';
import ChartComponent from './ChartComponent';
import './TaskSettings.css';

import { HandySvg } from 'handy-svg';
import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/tasks', false, /\.(svg)$/));

const TaskSettings = ({ handleCurrentWindow }) => {
  const { taskData, handleTaskDataChange } = useContext(TaskContext);

  // Разделяем chartData на отдельные состояния
  const [countIntervals, setCountIntervals] = useState(taskData.countIntervals);
  const [behavior, setBehavior] = useState(taskData.behavior.slice());
  const [countActions, setCountActions] = useState(taskData.count_actions);
  const [periodTime, setPeriodTime] = useState(
    Math.floor(taskData.task_time / (taskData.countIntervals - 1) * 1000)
  );

  // useEffect для обновления periodTime при изменении countIntervals
  useEffect(() => {
    setPeriodTime(
      Math.floor(taskData.task_time / (countIntervals - 1) * 1000)
    );
  }, [countIntervals, taskData.task_time]);

  const handleBack = () => handleCurrentWindow('default');

  const resetChartData = () => {
    setCountIntervals(taskData.countIntervals);
    setBehavior(taskData.behavior.slice());
    setCountActions(taskData.count_actions);
    setPeriodTime(
      Math.floor(taskData.task_time / (taskData.countIntervals - 1) * 1000)
    );
  };

  const saveChartData = () => {
    handleTaskDataChange('countIntervals', countIntervals);
    handleTaskDataChange('count_actions', countActions);
    handleTaskDataChange('behavior', behavior);
    handleBack();
  };

  const updateCountIntervals = (e) => {
    const newLength = parseInt(e.target.value, 10);
    setCountIntervals(newLength);

    // Обновляем длину behavior в зависимости от countIntervals
    setBehavior((prev) => {
      const updatedBehavior = [...prev];
      if (newLength > updatedBehavior.length) {
        for (let i = updatedBehavior.length; i < newLength; i++) {
          updatedBehavior.push(0);
        }
      } else if (newLength < updatedBehavior.length) {
        updatedBehavior.length = newLength;
      }
      return updatedBehavior;
    });
  };

  const HeaderTitle = () => (
    <>
      <div className='task-window-back-btn' onClick={handleBack}>
        <HandySvg src={images['back.svg']} className="logo-15x15" />
      </div>
      <div className="task-settings-header">
        <h3>Продвинутые настройки задачи</h3>
      </div>
    </>
  );

  const InputFields = () => (
    <div className="task-settings-fields">
      <InputField
        label="Интервал"
        type="number"
        placeholder="Введите количество интервалов"
        logo={images['todo.svg']}
        error={taskData.errors.interval}
        value={countIntervals}
        handleChange={updateCountIntervals}
        options={{minValue: 3, maxValue: 24}}
      />
    </div>
  );

  return (
    <div className="task-settings">
      {HeaderTitle()}
      {InputFields()}
      <div className='chart-content'>
        <ChartComponent
          countIntervals={countIntervals}
          behavior={behavior}
          periodTime={periodTime}
          handleBehaviorChange={(newBehavior) => setBehavior(newBehavior)}
        />
      </div>
      <div className="task-settings-footer">
        <button className="reset-button" onClick={resetChartData}>Сбросить настройки</button>
        <button className="save-button" onClick={saveChartData}>Сохранить изменения</button>
      </div>
    </div>
  );
};

export default TaskSettings;
