import React, { useState, useRef, useContext, useCallback } from 'react';
import { TaskContext } from '@components/Tasks/AddTaskWindow/TaskContext';
import { TimeContext } from './TimeContext';
import DatePicker from 'react-datepicker';
import { ru } from 'date-fns/locale';
import { addDays } from 'date-fns';

import './Calendar.css';

import WorkTime from './WorkTime';

export default function CalendarForm({ isActive }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const datePickerRef = useRef(null);

  const { defaultTime, handleGetRange } = useContext(TaskContext);
  const { calendarData, setCalendarData } = useContext(TimeContext);

  const addDateRange = useCallback((startDate, endDate) => {
    setCalendarData(prevRanges => [...prevRanges, {
      startDate: startDate,
      endDate: endDate,
      times: [
        defaultTime()
      ]
    }]);
  }, [defaultTime, setCalendarData]);

  const isValidIndex = useCallback((index, array) => index >= 0 && index < array.length, []);

  const addTimeToRange = useCallback((indexDay) => {
    setCalendarData(prevRanges => {
      const updatedRanges = [...prevRanges];

      if (isValidIndex(indexDay, updatedRanges)) {
        updatedRanges[indexDay] = {
          ...updatedRanges[indexDay],
          times: [...updatedRanges[indexDay].times, defaultTime()]
        };
      }

      return updatedRanges;
    });
  }, [defaultTime, isValidIndex, setCalendarData]);

  const removeTimeFromRange = useCallback((dayIndex, timeIndex) => {
    setCalendarData((prevWeeklyData) => {
      const updatedDays = [...prevWeeklyData];

      if (isValidIndex(dayIndex, updatedDays)) {
        const day = updatedDays[dayIndex];
        const updatedTimes = [...day.times];

        if (timeIndex >= 0 && timeIndex < updatedTimes.length) {
          updatedTimes.splice(timeIndex, 1);
          updatedDays[dayIndex] = {
            ...day,
            times: updatedTimes,
          };
        }
      }

      return updatedDays;
    });
  }, [isValidIndex, setCalendarData]);

  const editDateRange = useCallback((dayIndex, timeIndex, field, value) => {
    setCalendarData((prevRanges) => {
      const updatedRanges = [...prevRanges];

      if (isValidIndex(dayIndex, updatedRanges)) {
        const day = updatedRanges[dayIndex];
        const updatedTimes = [...day.times];

        if (isValidIndex(timeIndex, updatedTimes)) {
          updatedTimes[timeIndex] = {
            ...updatedTimes[timeIndex],
            [field]: value,
          };

          updatedRanges[dayIndex] = {
            ...day,
            times: updatedTimes,
          };
        }
      }

      return updatedRanges;
    });
  }, [isValidIndex, setCalendarData]);

  const getCurDay = (event) => {
    const datePickerElement = datePickerRef.current;
    if (!datePickerElement) return null;

    const dayElements = datePickerElement.querySelectorAll('.react-datepicker__day');

    for (let i = 0; i < dayElements.length; i++) {
      const dayElement = dayElements[i];
      const rect = dayElement.getBoundingClientRect();
      const isHovered = (
        event.clientX >= rect.left &&
        event.clientX <= rect.right &&
        event.clientY >= rect.top &&
        event.clientY <= rect.bottom
      );

      if (isHovered) {
        const dayString = dayElement.getAttribute('aria-label');

        // Регулярное выражение для извлечения дня, месяца и года
        const regex = /(\d{1,2})\s+([а-яА-Я]+)\s+(\d{4})/;
        const match = dayString.match(regex);

        if (match) {
          const day = match[1];
          const month = match[2];
          const year = match[3];

          // Массив для конвертации русских месяцев в английские
          const months = {
            'января': 'January',
            'февраля': 'February',
            'марта': 'March',
            'апреля': 'April',
            'мая': 'May',
            'июня': 'June',
            'июля': 'July',
            'августа': 'August',
            'сентября': 'September',
            'октября': 'October',
            'ноября': 'November',
            'декабря': 'December',
          };

          const engMonth = months[month];
          if (engMonth) {
            const dateString = `${day} ${engMonth} ${year}`;
            const date = new Date(dateString);
            return date;
          }
        }
      }
    }
    return null;
  };

  const deleteRange = useCallback((day) => {
    let isDeleted = false;
    setCalendarData(prevRanges => {
      const newRanges = prevRanges.filter(
        range => !(range.startDate <= day && day <= range.endDate)
      );
      isDeleted = newRanges.length !== prevRanges.length;
      return newRanges;
    });

    if (isDeleted) {
      setStartDate(null);
      setEndDate(null);
    }
    return isDeleted;
  }, [setCalendarData]);

  const isIntersect = (start, end) => {
    return calendarData.some(range =>
      (range.startDate <= start && start <= range.endDate) ||
      (range.startDate <= end && end <= range.endDate) ||
      (start <= range.startDate && range.startDate <= end)
    );
  };

  const handleMouseDown = (event) => {
    const curDay = getCurDay(event);
    if (curDay && !deleteRange(curDay)) {
      setStartDate(curDay);
      setEndDate(curDay);
    }
  };

  const handleMouseMove = (event) => {
    if (startDate) {
      const curDay = getCurDay(event);
      if (curDay && curDay >= startDate && !isIntersect(startDate, curDay)) {
        setEndDate(curDay);
      }
    }
  };

  const handleMouseUp = () => {
    if (endDate) {
      addDateRange(startDate, endDate);
    }
    setStartDate(null);
    setEndDate(null);
  };

  const handleHighlightDates = useCallback(() => {
    const handleShowRange = (start, end) => {
      highlightWithRanges[0]['react-datepicker__day--highlighted-border-start-days'].push(start);
      highlightWithRanges[1]['react-datepicker__day--highlighted-border-end-days'].push(end);
      for (var d = addDays(start, 1); d < end; d = addDays(d, 1)) {
        highlightWithRanges[2]['react-datepicker__day--highlighted-in-range-days'].push(d);
      }
    };

    let highlightWithRanges = [
      {
        "react-datepicker__day--highlighted-border-start-days": [],
      },
      {
        "react-datepicker__day--highlighted-border-end-days": [],
      },
      {
        "react-datepicker__day--highlighted-in-range-days": [],
      },
    ];

    handleShowRange(startDate, endDate);
    calendarData.forEach(function (item) {
      handleShowRange(item['startDate'], item['endDate']);
    });

    return highlightWithRanges;
  }, [calendarData, endDate, startDate]);

  const CalendarHeader = (
    { date,
      decreaseMonth,
      increaseMonth,
    }) => (
    <div style={{ display: "flex", justifyContent: "space-between", padding: "10px" }}>
      <div onClick={decreaseMonth}>
        {"<"}
      </div>
      <span>
        {date ? date.toLocaleDateString("ru-RU", {
          month: "long",
          year: "numeric"
        }) : "Неверная дата"}
      </span>
      <div onClick={increaseMonth}>
        {">"}
      </div>
    </div>
  );

  return (
    <div className={`calendar-calendar ${isActive ? '' : 'inactive'}`}>
      <div
        className="date-range-container"
        ref={datePickerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <DatePicker
          selectsRange
          inline
          dateFormat="dd MMMM yyyy"
          monthsShown={1}
          ariaLiveMessages={''}

          locale={ru}
          highlightDates={handleHighlightDates()}
          renderCustomHeader={CalendarHeader}
          dayClassName={(date) =>
            startDate ? 'disable-hover' : ''
          } // Добавление класса для отключения hover
        />
      </div>
      <div className='working-hours-container'>
        {calendarData.flatMap((dayValue, dayIndex) =>
          dayValue.times.map((timeValue, timeIndex) => (
            <WorkTime
              key={`${dayIndex}-${timeIndex}`}
              day={handleGetRange(dayValue)}
              dayIndex={dayIndex}
              time={timeValue}
              timeIndex={timeIndex}
              addTimeToRange={addTimeToRange}
              removeTimeFromRange={removeTimeFromRange}
              editor={editDateRange}
            />
          ))
        )}
      </div>
    </div>
  )
}
