import React, {useEffect, useState} from 'react';
import {HandySvg} from 'handy-svg';
import ImageUtils from '@components/imageUtils';
import './Pagination.css'

const images = ImageUtils.importAllImages(require.context('@assets/tasks', false, /\.(svg)$/));

const CountPerPage = ({taskPerPage, handleCountItems}) => {
  return (
    <div className="task-count-container">
      <select value={taskPerPage} onChange={handleCountItems}>
        <option value={15}>15 задач</option>
        <option value={25}>25 задач</option>
        <option value={50}>50 задач</option>
        <option value={100}>100 задач</option>
      </select>
    </div>
  )
}

const PaginationPages = ({activePage, countPages, onPageChange}) => {
  const [pages, setPages] = useState([]);

  const handlePageNumber = (pageNumber) => {
    onPageChange(pageNumber);
  };

  const TaskPage = ({pageNumber}) => (
    <button
      key={pageNumber}
      className={`page ${activePage === pageNumber ? 'active' : ''}`}
      onClick={() => handlePageNumber(pageNumber)}
    >
      {pageNumber}
    </button>
  );

  const PrevPageArrow = () => (
    <button
      className={`page ${activePage > 1 ? 'active' : ''}`}
      onClick={() => handlePageNumber(activePage > 1 ? activePage - 1 : 1)}
    >
      <HandySvg src={images['prevPage.svg']} alt="prevPage.svg" className="currentColor logo-15x15"/>
    </button>
  )

  const NextPageArrow = () => (
    <button
      className={`page ${activePage < countPages ? 'active' : ''}`}
      onClick={() => handlePageNumber(activePage < countPages ? activePage + 1 : countPages)}
    >
      <HandySvg src={images['nextPage.svg']} alt="nextPage.svg" className="currentColor logo-15x15"/>
    </button>
  )

  const TripplePoints = () => (
    <span className="triple-points">...</span>
  )

  useEffect(() => {
    const newPages = [];
    let startPage = 2;
    let endPage = countPages - 1;

    if (activePage < 5) {
      endPage = Math.min(endPage, 5);
    } else if (activePage > countPages - 4) {
      startPage = Math.max(startPage, countPages - 4);
    } else {
      startPage = activePage - 1;
      endPage = activePage + 1;
    }

    newPages.push(<PrevPageArrow key={-1}/>);
    newPages.push(<TaskPage key={1} pageNumber={1}/>);
    startPage > 2 && newPages.push(<TripplePoints key={-2}/>)

    for (let i = startPage; i <= endPage; i++) {
      newPages.push(<TaskPage key={i} pageNumber={i}/>);
    }

    endPage < countPages - 1 && newPages.push(<TripplePoints key={-3}/>)
    newPages.push(countPages > 1 && <TaskPage key={countPages} pageNumber={countPages}/>);
    newPages.push(<NextPageArrow key={-4}/>)

    setPages(newPages);
  }, [activePage, countPages]);

  return (
    <div className="pages-container">
      {pages}
    </div>
  )
}

const Pagination = ({activePage, countPages, taskPerPage, onPageChange, onItemsPerPageChange}) => {
  const handleCountItems = (event) => {
    const countItems = parseInt(event.target.value);
    onItemsPerPageChange(countItems);
  };

  return (
    <div className="tasks-pagination">
      <CountPerPage taskPerPage={taskPerPage} handleCountItems={handleCountItems}/>
      <PaginationPages activePage={activePage} countPages={countPages} onPageChange={onPageChange}/>
    </div>
  );
};

export default Pagination;
