import React, {useState, useEffect, useRef} from 'react';
import './FAQ.css';
import { HandySvg } from 'handy-svg';
import ImageUtils from '@components/imageUtils';

const images = ImageUtils.importAllImages(require.context('@assets/home', false, /\.(svg)$/));

export default function FAQ() {
  const questions = [
    {
      question: 'Как пополнить кошелек?',
      answer: `
      <strong>Выберите способ пополнения:</strong> банковская карта или перевод.
      <strong>Введите сумму:</strong> укажите, на сколько хотите пополнить.
      <strong>Подтвердите операцию:</strong> проверьте данные и подтвердите транзакцию.
      <strong>Проверьте баланс:</strong> убедитесь, что средства зачислены.
      Если возникли вопросы, обратитесь в службу поддержки.`
    },
    {
      question: 'Как пополнить кошелек?',
      answer: `
      <strong>Выберите способ пополнения:</strong> банковская карта или перевод.
      <strong>Введите сумму:</strong> укажите, на сколько хотите пополнить.
      <strong>Подтвердите операцию:</strong> проверьте данные и подтвердите транзакцию.
      <strong>Проверьте баланс:</strong> убедитесь, что средства зачислены.
      Если возникли вопросы, обратитесь в службу поддержки.`
    },
    {
      question: 'Как пополнить кошелек?',
      answer: `
      <strong>Выберите способ пополнения:</strong> банковская карта или перевод.
      <strong>Введите сумму:</strong> укажите, на сколько хотите пополнить.
      <strong>Подтвердите операцию:</strong> проверьте данные и подтвердите транзакцию.
      <strong>Проверьте баланс:</strong> убедитесь, что средства зачислены.
      Если возникли вопросы, обратитесь в службу поддержки.`
    },
    {
      question: 'Как пополнить кошелек?',
      answer: `
      <strong>Выберите способ пополнения:</strong> банковская карта или перевод.
      <strong>Введите сумму:</strong> укажите, на сколько хотите пополнить.
      <strong>Подтвердите операцию:</strong> проверьте данные и подтвердите транзакцию.
      <strong>Проверьте баланс:</strong> убедитесь, что средства зачислены.
      Если возникли вопросы, обратитесь в службу поддержки.`
    },
    {
      question: 'Как пополнить кошелек?',
      answer: `
      <strong>Выберите способ пополнения:</strong> банковская карта или перевод.
      <strong>Введите сумму:</strong> укажите, на сколько хотите пополнить.
      <strong>Подтвердите операцию:</strong> проверьте данные и подтвердите транзакцию.
      <strong>Проверьте баланс:</strong> убедитесь, что средства зачислены.
      Если возникли вопросы, обратитесь в службу поддержки.`
    },
    {
      question: 'Как пополнить кошелек?',
      answer: `
      <strong>Выберите способ пополнения:</strong> банковская карта или перевод.
      <strong>Введите сумму:</strong> укажите, на сколько хотите пополнить.
      <strong>Подтвердите операцию:</strong> проверьте данные и подтвердите транзакцию.
      <strong>Проверьте баланс:</strong> убедитесь, что средства зачислены.
      Если возникли вопросы, обратитесь в службу поддержки.`
    },
  ];

  const FaQCard = ({ question, index }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [height, setHeight] = useState('0px');
    const answerRef = useRef(null);

    const handleOpen = () => {
      setIsOpen(!isOpen);
    };

    useEffect(() => {
      setHeight(isOpen ? `${answerRef.current.scrollHeight}px` : '0px');
    }, [isOpen]);

    return (
      <div className='faq-item' key={index} onClick={handleOpen}>
        <div className='faq-question'>
          <div className='faq-question-leftside'>
            <div className='faq-question-logo'>
              <HandySvg src={images[`wallet.svg`]} />
            </div>
            {question.question}
          </div>
          <div className='faq-question-rightside'>
            <HandySvg
              src={images[`angle.svg`]}
              className={`faq-angle ${isOpen ? 'open' : ''}`}
            />
          </div>
        </div>
        <div
          className='faq-answer'
          style={{maxHeight: height, overflow: 'hidden', transition: 'max-height 0.3s ease'}}
          ref={answerRef}
          dangerouslySetInnerHTML={{__html: question.answer.split('\n').join('<br />')}}
        />
      </div>
    );
  };

  return (
    <div className='faq'>
      <div className='home-chapter-box'>ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</div>
      <div className='faq-label'>Мы ответим на все ваши вопросы</div>
      <div className='faq-items'>
        {questions.map((question, index) => (
          <FaQCard key={index} question={question} index={index} />
        ))}
      </div>
      <a
        href="https://t.me/toupSupport_bot"
        target="_blank"
        rel="noopener noreferrer"
        style={{textDecoration: 'none'}}
        className='faq-button'
      >
        Задать вопрос
      </a>
    </div>
  );
}
