import React, {useState, useContext, useCallback} from 'react';
import {editName, editEmail, resetPassword, addChannel, editChannel} from '@components/shared/api';
import { AuthContext } from '@context/AuthContext';
import {NotificationContext} from "@context/Notification/NotificationContext";
import Header from '@components/Header/Header';
import UserInfo from "@components/Profile/Options/ProfileSettings/UserInfo";
import ChannelsList from "@components/Profile/Options/ProfileSettings/ChannelsList";
import EditChannelPopup from "@components/Profile/Options/ProfileSettings/EditChannelPopup";
import PasswordReset from "@components/Profile/Options/ProfileSettings/PasswordReset";
import Footer from '@components/Footer/Footer';
import OptionsSidebar from './OptionsSidebar';
import './Options.css';

export default function ProfileSettings() {
  const { userInfo, handleChangeUserInfo } = useContext(AuthContext);
  const [username, setUsername] = useState(userInfo.name);
  const [email, setEmail] = useState(userInfo.email);
  const [newTelegramName, setNewTelegramName] = useState('');
  const [newTelegramLink, setNewTelegramLink] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [telegrams, setTelegrams] = useState(userInfo.channels || []);
  const [editingIndex, setEditingIndex] = useState(null); // Индекс редактируемого канала
  const {showError, showSuccess} = useContext(NotificationContext);

  const saveButton = useCallback(async () => {
    const update = {name: username, email: email};
    if (username !== userInfo.name) {
      const response = await editName(username);
      if (response.data.status) {
        update['name'] = response.data.name;
        setUsername(response.data.name);
        showSuccess('Имя успешно сохранено!');
      } else {
        showError(response?.data?.message ? response?.data?.message : 'При сохранении имени произошла ошибка!');
      }
    }
    if (email !== userInfo.email) {
      const response = await editEmail(email);
      if (response?.data?.status) {
        update['email'] = response.data.mail;
        setEmail(response.data.mail);
        showSuccess('Почта успешно сохранена!');
      } else {
        showError(response?.data?.message ? response?.data?.message : 'При сохранении почты произошла ошибка!');
      }
    }
    handleChangeUserInfo(update);
  }, [username, email, userInfo.name, userInfo.email, handleChangeUserInfo, showSuccess, showError]);

  const handleUsernameChange = useCallback((e) => {
    setUsername(e.target.value);
  }, []);

  const handleEmailChange = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const handlePasswordChange = useCallback((e) => {
    setPassword(e.target.value);
  }, []);

  const handleConfirmPasswordChange = useCallback((e) => {
    setConfirmPassword(e.target.value);
  }, []);

  const handleResetPassword = useCallback(async () => {
    if (password !== confirmPassword) {
      showError('Пароли не совпадают!');
      return;
    }

    if (password === '' || confirmPassword === '') {
      showError('Поля с новым паролем не могут быть пустыми!');
      return;
    }

    try {
      const response = await resetPassword(password);
      if (response.data.status) {
        showSuccess('Пароль успешно изменен!');
        setPassword('');
        setConfirmPassword('');
      } else {
        showError(response.data.message ? response.data.message : 'При изменении пароля произошла ошибка!');
      }
    } catch (error) {
      console.error('Ошибка при изменении пароля:', error);
    }
  }, [password, confirmPassword, showError, showSuccess]);

  const handleAddChannel = useCallback(async () => {
    if (!newTelegramName || !newTelegramLink) {
      showError('Пожалуйста, заполните все поля!');
      return;
    }
    try {
      const response = await addChannel(newTelegramName, newTelegramLink);
      if (response?.data?.status) {
        showSuccess('Канал успешно добавлен!');
        // Обновляем список каналов с добавленным новым каналом
        setTelegrams((prevTelegrams) => [...prevTelegrams, {name: newTelegramName, link: newTelegramLink}]);
        setNewTelegramName('');
        setNewTelegramLink('');
      } else {
        showError(response?.data?.message ? response?.data?.message : 'При добавлении канала произошла ошибка')
      }
    } catch (error) {
      console.error('Ошибка при добавлении канала:', error);
    }
  }, [newTelegramName, newTelegramLink, showError, showSuccess]);

  const handleSaveEdit = async (updatedChannel) => {
    try {
      const response = await editChannel(editingIndex, updatedChannel);
      if (response?.data?.status) {
        setTelegrams((prevTelegrams) =>
          prevTelegrams.map((t, i) => (i === editingIndex ? updatedChannel : t))
        );
        setEditingIndex(null); // Закрываем popup после сохранения
        showSuccess('Данные успешно изменены');
      } else {
        showError('Во время изменения данных произошла ошибка');
      }
    } catch (error) {
      console.error('Ошибка при редактировании канала:', error);
    }
  };

  const handleEditClick = (index) => {
    setEditingIndex(index);
  };

  const handleCancelEdit = () => {
    setEditingIndex(null);
  };

  return (
    <>
      <Header />
      <div className='profile-settings'>
        <div className='t-28' style={{ marginTop: "35px", marginBottom: "20px" }}>Личная информация</div>
        <div className='profile-setting-container'>
          <OptionsSidebar />
          <div className='profile-settings-info-container'>
            <UserInfo
              username={username}
              email={email}
              handleUsernameChange={handleUsernameChange}
              handleEmailChange={handleEmailChange}
              saveButton={saveButton}
            />
            <ChannelsList
              telegrams={telegrams}
              setTelegrams={setTelegrams}
              newTelegramName={newTelegramName}
              newTelegramLink={newTelegramLink}
              handleNameChange={(e) => setNewTelegramName(e.target.value)}
              handleLinkChange={(e) => setNewTelegramLink(e.target.value)}
              addChannel={handleAddChannel}
              handleEditClick={handleEditClick}
            />
            {editingIndex !== null && (
              <EditChannelPopup
                telegram={telegrams[editingIndex]}
                onSave={handleSaveEdit}
                onCancel={handleCancelEdit}
              />
            )}
            <PasswordReset
              password={password}
              confirmPassword={confirmPassword}
              handlePasswordChange={handlePasswordChange}
              handleConfirmPasswordChange={handleConfirmPasswordChange}
              resetPassword={handleResetPassword}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
