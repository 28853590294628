import React, {useRef, useEffect, useContext, useCallback} from 'react';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import dragDataPlugin from 'chartjs-plugin-dragdata';
import {TaskContext} from "@components/Tasks/AddTaskWindow/TaskContext";

Chart.register(dragDataPlugin);

const ChartComponent = ({
                          countIntervals,
                          behavior,
                          periodTime,
                          handleBehaviorChange,
                        }) => {
  const chartRef = useRef(null);
  const {taskData, updateBehavior} = useContext(TaskContext);

  // Функция для форматирования времени
  const formatTime = (date) => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  // Генерация временных меток для оси X
  const createTimeLabels = useCallback(() => {
    const currentDate = new Date();
    const labels = [];
    for (let i = 0; i < countIntervals; i++) {
      const timeLabel = new Date(currentDate.getTime() + i * periodTime);
      labels.push(formatTime(timeLabel));
    }
    return labels;
  }, [countIntervals, periodTime]);

  // Генерация данных для графика
  const createChartData = () => ({
    labels: createTimeLabels(),
    datasets: [
      {
        label: 'Behavior',
        data: behavior,
        borderColor: 'rgba(45, 142, 255, 1)',
        borderWidth: 3,
        pointBackgroundColor: 'white',
        pointRadius: 10,
        pointHoverRadius: 10,
        fill: true,
      },
    ],
  });

  // Обновление графика при изменении поведения, количества интервалов или периода
  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      const ctx = chart.ctx;
      const gradient = ctx.createLinearGradient(0, 0, 0, chart.chartArea.bottom);
      gradient.addColorStop(0, 'rgba(45,142,255,1)');
      gradient.addColorStop(1, 'rgba(45,142,255,0)');
      chart.data.datasets[0].backgroundColor = gradient;
      chart.data.datasets[0].data = behavior;
      chart.data.labels = createTimeLabels();
      chart.update();
    }
  }, [behavior, countIntervals, createTimeLabels, periodTime]);

  // Обработчик завершения перетаскивания точки
  const handleDragEnd = (e, datasetIndex, index, value) => {
    const currentData = [...chartRef.current.data.datasets[0].data];
    currentData[index] = value;
    // debugger
    const updatedBehavior = updateBehavior(currentData, currentData.length, taskData.count_actions);
    // updatedBehavior[index] = value;

    // Передаем обновленные данные через callback
    handleBehaviorChange(updatedBehavior);

    // Обновляем лимиты оси Y
    const maxValue = Math.max(...updatedBehavior);
    const chart = chartRef.current;
    if (chart) {
      chart.options.scales.y.min = 0;
      chart.options.scales.y.max = maxValue * 1.3;
      chart.update('none');
    }
  };

  return (
    <Line
      ref={chartRef}
      data={createChartData()}
      options={{
        animation: false,
        scales: {
          x: {
            grid: {display: false},
            ticks: {
              callback: function (value) {
                return this.getLabelForValue(value).split('\n');
              },
            },
          },
          y: {
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: Math.max(...behavior) * 1.5,
            grid: {display: false},
          },
        },
        plugins: {
          legend: {display: false},
          tooltip: {
            callbacks: {
              label: (context) => ` ${context.raw}`,
            },
          },
          dragData: {
            round: 1,
            showTooltip: true,
            onDragEnd: handleDragEnd,
          },
        },
        interaction: {
          mode: 'index',
          intersect: false,
        },
      }}
    />
  );
};

export default ChartComponent;
