import React, {useState, useContext, useEffect} from 'react'
import {fetchAuth} from '@components/shared/api';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '@context/AuthContext';
import {NotificationContext} from "@context/Notification/NotificationContext";
import InputField from '@components/shared/InputField';

import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/auth', false, /\.(svg)$/));

export default function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { handleClose, handleRegistration, handleReset, login } = useContext(AuthContext);
  const {showError} = useContext(NotificationContext);
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    setErrors({
      email: false,
      password: false,
    })
  }, [password, email])

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;

    const newErrors = {
      email: !emailRegex.test(email), // Ошибка, если email некорректен
      password: password.trim().length === 0, // Ошибка, если пароль пустой
    };

    setErrors(newErrors);

    if (newErrors.email) {
      showError('Введите корректный email адрес!');
      return;
    }
    if (newErrors.password) {
      showError('Введите пароль!');
      return;
    }

    try {
      const response = await fetchAuth(email, password);
      if (response?.data?.status) {
        localStorage.setItem('token', response.data.token);
        login();
        handleClose();
        navigate('/');
      } else {
        showError(response?.data?.msg ? response?.data?.msg : 'Произошла ошибка!');
        setErrors({
          email: true,
          password: true,
        })
      }
    } catch (err) {
      console.error(err);
      showError(`Ошибка при авторизации: ${err}`);
    }
  };

  return (
    <div className="auth-form">
      <div className='label'>
        <div>
          <p className="text-blue-left">Войдите в аккаунт</p>
          <h2>МЫ РАДЫ ВАС ВИДЕТЬ СНОВА</h2>
        </div>
        <img src={images['close.svg']} alt='close' onClick={handleClose}/>
      </div>
      <form onSubmit={handleSubmit}>
        <InputField
          label="Email"
          type="text"
          placeholder="Ваша почта"
          logo={images['login.svg']}
          error={errors.email}
          value={email}
          handleChange={(e) => {
            setEmail(e.target.value)
            setErrors({
              email: false,
              password: false,
            })
          }}
        />
        <div>
          <InputField
            label="Пароль"
            type="password"
            placeholder="Введите пароль"
            logo={images['password.svg']}
            error={errors.password}
            value={password}
            handleChange={(e) => {
              setPassword(e.target.value);
              setErrors({
                email: false,
                password: false,
              })
            }}
          />
          <div className='reset-password-btn' onClick={handleReset}>Забыли пароль?</div>
        </div>
        <button type="submit" style={{marginTop: '190px'}}>Войти</button>
      </form>
      <p className='switch-auth-window'>Нет аккаунта? <div onClick={handleRegistration}>Регистрация</div></p>
    </div>
  );
}
