import React, {useRef, useState} from 'react';
import ProfileInfoSection from '@components/Profile/Banners/ProfileInfoSection';
import ResourcesSection from "@components/Profile/Banners/ResourcesSection";
import SupportSection from "@components/Profile/Banners/SupportSection";
import './BannerSlider.css';

const BannerSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0); // Индекс активного элемента
  const [offset, setOffset] = useState(0); // Текущее смещение (пиксели)
  const startX = useRef(0); // Начальная позиция курсора/касания
  const isDragging = useRef(false); // Флаг перетаскивания
  const containerWidth = useRef(0); // Ширина контейнера
  const bannerGap = 0; // Промежуток между баннерами в пикселях

  const banners = [<ProfileInfoSection/>, <ResourcesSection/>, <SupportSection/>];

  const handleDragStart = (event) => {
    startX.current = event.type === 'touchstart' ? event.touches[0].clientX : event.clientX;
    isDragging.current = true;

    // Определяем ширину контейнера
    const container = document.querySelector('.banner-slider');
    containerWidth.current = container.offsetWidth + bannerGap;
  };

  const handleDragMove = (event) => {
    if (!isDragging.current) return;

    const clientX = event.type === 'touchmove' ? event.touches[0].clientX : event.clientX;
    const deltaX = clientX - startX.current; // Разница в пикселях
    setOffset(deltaX); // Обновляем временное смещение
  };

  const handleDragEnd = () => {
    if (!isDragging.current) return;

    isDragging.current = false;

    // Если смещение больше 30 пикселей, переключаем баннер
    if (Math.abs(offset) > 30) {
      if (offset > 0 && currentIndex > 0) {
        setCurrentIndex((prevIndex) => prevIndex - 1); // Переключаем влево
      } else if (offset < 0 && currentIndex < banners.length - 1) {
        setCurrentIndex((prevIndex) => prevIndex + 1); // Переключаем вправо
      }
    }

    // Сбрасываем временное смещение
    setOffset(0);
  };

  // Рассчитываем позицию контейнера: индекс + временное смещение
  const position = -(currentIndex * containerWidth.current) + offset;

  return (
    <div
      className="banner-slider"
      onMouseDown={handleDragStart}
      onMouseMove={handleDragMove}
      onMouseUp={handleDragEnd}
      onMouseLeave={handleDragEnd}
      onTouchStart={handleDragStart}
      onTouchMove={handleDragMove}
      onTouchEnd={handleDragEnd}
    >
      <div
        className="banners-container"
        style={{
          transform: `translateX(${position}px)`,
          transition: isDragging.current ? 'none' : 'transform 0.5s ease',
        }}
      >
        {banners.map((banner, index) => (
          <div key={index} className="banner">
            {banner}
          </div>
        ))}
      </div>
      <div className="dots-container">
        {banners.map((_, index) => (
          <div
            key={index}
            className={`dot ${currentIndex === index ? 'active' : ''}`}
            onClick={() => setCurrentIndex(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default BannerSlider;
