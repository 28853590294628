import React, {useEffect, useContext, useState, useRef, useCallback} from "react";
import {AuthContext} from "@context/AuthContext";
import RegistrationForm from "./RegistrationForm";
import RegistrationInfoBlock from "./RegistrationInfoBlock";
import {fetchRegistration} from "@components/shared/api";
import "./Registration.css";
import {useNavigate} from "react-router-dom";
import {NotificationContext} from "@context/Notification/NotificationContext";

// Функция генерации токена
const generateTempToken = () => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let token = "";
  for (let i = 0; i < 12; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    token += characters[randomIndex];
  }
  return token;
};

const Registration = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [channel, setChannel] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [tempToken, setTempToken] = useState(generateTempToken());
  const {handleClose, login} = useContext(AuthContext);
  const {showError} = useContext(NotificationContext);
  const [stageStep, setStageStep] = useState("Registration");
  const [isSending, setIsSending] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [code, setCode] = useState(["", "", "", ""]);
  const [ptr, setPtr] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const containerRef = useRef(null);
  const navigate = useNavigate();

  const handleRegistration = useCallback(async (codeString) => {
    if (password !== confirmPassword) {
      showError("Пароли не совпадают");
      return;
    }

    setIsSending(true);
    try {
      const response = await fetchRegistration(email, password, name, channel, tempToken, codeString);
      if (response.data?.status) {
        // containerRef.current.classList.add("bouncing", "success");
        setIsSuccess(true)
        setTimeout(() => {
          setIsSuccess(false)
          localStorage.setItem("token", response.data.token);
          login();
          handleClose();
          navigate("/");
          setIsSending(false);
        }, 2000);
      } else {
        if (!containerRef.current.classList.contains("success")) {
          setIsError(true)
          showError(response?.data?.msg || "Не удалось зарегистрироваться");
          setTimeout(() => {
            setIsError(false)
            setIsSending(false);
          }, 500);
        }
      }
    } catch (err) {
      setIsSending(false);
      showError('Что-то пошло не так!')
    }
  });

  useEffect(() => {
    const handleMouseDown = (e) => {
      if (!e.target.closest('.auth-window') && !e.target.closest('.notification-window')) {
        handleClose();
      }
    };

    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleClose]);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    updateIsMobile();
    window.addEventListener("resize", updateIsMobile);

    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);

  const handleCodeStep = () => {
    setStageStep("Code");
  };

  const handleRegistrationStep = () => {
    setStageStep("Registration");
  };

  useEffect(() => {
    const handlePaste = (e) => {
      const clipboardData = e.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData("Text").trim();

      if (/^\d+$/.test(pastedData) && pastedData.length === code.length) {
        const newCode = pastedData.split("").slice(0, code.length);
        setCode(newCode);
        setPtr(newCode.length);
      }
    };

    document.addEventListener("paste", handlePaste);

    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, [code]);

  useEffect(() => {
    if (code.every((char) => char !== "")) {
      const register = async () => {
        handleRegistration(code.join("")).then(() => {
          setCode(["", "", "", ""]); // Очищаем код
          setPtr(0); // Сбрасываем указатель
        });
      };

      register();
    }
  }, [code]);

  const CodeStage = React.memo(() => {
    const inputRef = useRef(null);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, [ptr, code]); // Обновляем фокус при изменении кода или указателя

    const handleInputChange = (e) => {
      const inputValue = e.nativeEvent.data;
      if (/^[0-9]$/.test(inputValue) && ptr < code.length) {
        const newCode = [...code];
        newCode[ptr] = inputValue;
        setCode(newCode);
        setPtr((prevPtr) => prevPtr + 1);
      }
    };

    const handleKeyDown = (e) => {
      if (e.key === "Backspace" && ptr > 0) {
        const newCode = [...code];
        newCode[ptr - 1] = "";
        setCode(newCode);
        setPtr((prevPtr) => prevPtr - 1);
      }
    };

    const handleContainerClick = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    return (
      <div className="auth-form" style={{gap: "5px"}}>
        <p className="text-blue-left">Подтвердите код</p>
        <h2>Мы отправили код на вашу почту</h2>
        <input
          ref={inputRef}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          maxLength={code.length}
          className="hidden-input"
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          value={code.join("")}
        />

        <div
          ref={containerRef}
          className={`code-field-container${isError ? " shaking error" : ""}${isSuccess ? " bouncing success" : ""}`}
          onClick={handleContainerClick}
        >
          {code.map((char, index) => (
            <div
              key={index}
              className={`code-field-item${ptr === index ? " active" : ""}`}
            >
              {char || "_"}
            </div>
          ))}
        </div>

        <div style={{margin: "auto 0 0 0"}}>
          <button
            className="reset-back-btn"
            onClick={() => {
              setCode(["", "", "", ""]);
              setPtr(0);
              handleRegistrationStep();
            }}
            style={{marginBottom: 0}}
          >
            Назад
          </button>
        </div>
      </div>
    );
  });


  // const MemoizedCodeStage = React.memo(CodeStage);

  return (
    <div className="auth-window-overlay">
      <div className="auth-window">
        <div className="auth-content">
          {stageStep === "Registration" ? (
            <RegistrationForm
              handleCodeStep={handleCodeStep}
              email={email}
              setEmail={setEmail}
              name={name}
              setName={setName}
              channel={channel}
              setChannel={setChannel}
              password={password}
              setPassword={setPassword}
              confirmPassword={confirmPassword}
              setConfirmPassword={setConfirmPassword}
              handleClose
              temp_token={tempToken}
            />
          ) : (
            <CodeStage/>
          )}
          <RegistrationInfoBlock/>
        </div>
      </div>
    </div>
  );
};

export default Registration;
