import React, {useContext, useEffect, useCallback} from 'react';
import {addTask} from '@components/shared/api';
import { TimeProvider } from './Calendar/TimeContext';
import { TaskContext } from '@components/Tasks/AddTaskWindow/TaskContext';
import { AuthContext } from '@context/AuthContext';
import {NotificationContext} from '@context/Notification/NotificationContext';
import BlueRectangle from './Default/BlueRectangle';
import TaskForm from './Default/TaskForm';
import TaskSettings from './AdvancedSettings/TaskSettings';
import Calendar from './Calendar/Calendar';
import './TaskWindow.css';

const TaskWindow = ({handleClose}) => {
  const authContext = useContext(AuthContext);
  const taskContext = useContext(TaskContext);
  const notificationContext = useContext(NotificationContext);

  const [currentWindow, setCurrentWindow] = React.useState('default');
  const [isSending, setIsSending] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);

  useEffect(() => {
    // Показать окно с анимацией
    setIsVisible(true);
  }, []);

  const wrapperHandleClose = useCallback(() => {
    setIsVisible(false);
    setTimeout(() => {
        handleClose()
      }, 100
    )
  }, [handleClose]);

  const handleCurrentWindow = (window) => {
    setCurrentWindow(window);
  };

  const sendTasksToServer = async () => {
    if (isSending) return;

    const { logout } = authContext;
    const { taskData, handleBehaviour, errorHandler, fieldsChecker } = taskContext;
    const {showError, showSuccess} = notificationContext;

    if (fieldsChecker()) {
      setIsSending(true);
      try {
        const response = await addTask(taskData, handleBehaviour);

        if (response.data.status) {
          wrapperHandleClose();
          showSuccess('Задача успешно добавлена!');
        } else {
          if (response.data.msg === 'Dont have permission') {
            logout();
          } else {
            errorHandler(response.data.msg, response.data.field_error);
            showError(response.data.msg);
          }
        }
      } catch (error) {
        console.error('Ошибка при отправке задач:', error);
      } finally {
        setIsSending(false);
      }
    }
  };

  useEffect(() => {
    const handleMouseDown = (e) => {
      const taskWindow = e.target.closest('.task-window');
      const calendar = e.target.closest('.react-datepicker');
      const notification = e.target.closest('.notification-window');

      if (!taskWindow && !calendar && !notification) {
        wrapperHandleClose();
      }
    };

    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        wrapperHandleClose();
      }
    };

    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('keydown', handleKeyDown);
    document.body.classList.add('no-scroll');

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('keydown', handleKeyDown);
      document.body.classList.remove('no-scroll');
    };
  }, [wrapperHandleClose]);

  const renderCurrentWindow = () => {
    if (currentWindow === 'default') {
      return (
        <div className={`task-content`}>
          <TaskForm
            handleCurrentWindow={handleCurrentWindow}
            sendTasksToServer={sendTasksToServer}
            isSending={isSending}
            handleClose={wrapperHandleClose}
          />
          <BlueRectangle />
        </div>
      );
    } else if (currentWindow === 'advanced-settings') {
      return <TaskSettings handleCurrentWindow={handleCurrentWindow}/>;
    } else if (currentWindow === 'calendar') {
      return (
        <TimeProvider>
          <Calendar handleCurrentWindow={handleCurrentWindow}/>
        </TimeProvider>
      );
    }

    return null;
  };

  return (
    <div className="task-window-overlay">
      <div className={`task-window ${isVisible && currentWindow}`}>
        {renderCurrentWindow()}
      </div>
    </div>
  );
};

export default TaskWindow;
