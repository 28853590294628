import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

// Функция для получения темы из localStorage или по умолчанию из настроек браузера
const fetchTheme = () => {
  const savedTheme = localStorage.getItem('theme');
  if (savedTheme) {
    return savedTheme;
  } else {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const userPrefersDark = mediaQuery.matches;
    return userPrefersDark ? 'dark' : 'light';
  }
};

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(fetchTheme());

  useEffect(() => {
    document.body.setAttribute('data-theme', theme);
  }, [theme])

  const toggleTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
