import React, {useContext, useState, useEffect, useCallback} from "react";
import {editTask} from "@components/shared/api";
import {TaskContext} from "@components/Tasks/AddTaskWindow/TaskContext";
import {AuthContext} from "@context/AuthContext";
import {NotificationContext} from "@context/Notification/NotificationContext";
import BlueRectangle from "@components/Tasks/AddTaskWindow/Default/BlueRectangle";
import EditTaskForm from "@components/Tasks/TasksPageComponents/TaskCard/Header/EditTaskForm";
import TaskSettings from "@components/Tasks/AddTaskWindow/AdvancedSettings/TaskSettings";

const EditWindow = ({task, handleClose, onUpdate}) => {
  const authContext = useContext(AuthContext);
  const taskContext = useContext(TaskContext);
  const notificationContext = useContext(NotificationContext);

  const [currentWindow, setCurrentWindow] = useState("default");
  const [isSending, setIsSending] = useState(false);
  const [isVisible, setIsVisible] = React.useState(false);

  const {logout} = authContext;
  const {taskData, handleBehaviour, errorHandler, fieldsChecker} = taskContext;
  const {showError, showSuccess} = notificationContext;

  useEffect(() => {
    // Показать окно с анимацией
    setIsVisible(true);
  }, []);

  const wrapperHandleClose = useCallback(() => {
    setIsVisible(false);
    setTimeout(() => {
        handleClose()
      }, 100
    )
  }, [handleClose]);

  const handleCurrentWindow = (window) => {
    setCurrentWindow(window);
  };

  const sendTasksToServer = async () => {
    if (isSending) return;
    if (fieldsChecker()) {
      setIsSending(true);
      try {
        const response = await editTask(taskData, handleBehaviour);

        if (response?.data?.status) {
          const updatedTask = {
            ...task,
            count_actions: taskData.count_actions,
            task_time: taskData.task_time,
            spread: taskData.spread,
            task_obj: taskData.task_obj,
          };
          onUpdate(updatedTask);
          wrapperHandleClose();
          showSuccess("Задача успешно обновлена!");
        } else {
          if (response.data.msg === "Dont have permission") {
            logout();
          } else {
            errorHandler(response.data.msg, response.data.field_error);
            showError(response.data.msg);
          }
        }
      } catch (error) {
        console.error("Ошибка при обновлении задачи:", error);
        showError("Произошла ошибка при обновлении задачи.");
      } finally {
        setIsSending(false);
      }
    } else {
      showError("Заполните все обязательные поля.");
    }
  };

  useEffect(() => {
    const handleMouseDown = (e) => {
      const taskWindow = e.target.closest(".task-window");
      const calendar = e.target.closest(".react-datepicker");

      if (!taskWindow && !calendar) {
        wrapperHandleClose();
      }
    };

    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        wrapperHandleClose();
      }
    };

    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("keydown", handleKeyDown);
    document.body.classList.add("no-scroll");

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("keydown", handleKeyDown);
      document.body.classList.remove("no-scroll");
    };
  }, [wrapperHandleClose]);

  const renderCurrentWindow = () => {
    if (currentWindow === "default") {
      return (
        <div className="task-content">
          <EditTaskForm
            handleCurrentWindow={handleCurrentWindow}
            sendTasksToServer={sendTasksToServer}
            isSending={isSending}
            handleClose={handleClose}
          />
          <BlueRectangle/>
        </div>
      );
    } else if (currentWindow === "advanced-settings") {
      return <TaskSettings handleCurrentWindow={handleCurrentWindow}/>;
    }
  };

  return (
    <div className="task-window-overlay">
      <div className={`task-window ${isVisible && currentWindow}`}>
        {renderCurrentWindow()}
      </div>
    </div>
  );
};

export default EditWindow;
