import React from 'react';
import './SupportSection.css';
import ImageUtils from '@components/imageUtils';

const images = ImageUtils.importAllImages(require.context('@assets/profile', false, /\.(svg)$/));

const SupportSection = React.memo(() => {
  return (<section>
    <div className="resources-text">
      <div className="banner-label">Поддержка</div>
      <h2>Мы всегда готовы помочь вам</h2>
      <div>Создайте тикет по соответствующей теме и
        мы вам ответим в течении 24 часов
      </div>
      <a
        href="https://t.me/toupSupport_bot"
        target="_blank"
        rel="noopener noreferrer"
        className="subscription-button"
        style={{marginTop: '10px'}}
      >
        Приобрести сейчас
      </a>
    </div>
    <img
      src={images['chuvachki3.svg']}
      alt="logo"
      className="chuvachki"
      draggable="false" // Запрещаем перетаскивание
    />
  </section>)
});

export default SupportSection;
