import React, { useContext } from 'react';
import { TaskContext } from '@components/Tasks/AddTaskWindow/TaskContext';
import { TimeContext } from './TimeContext';
import WorkTime from './WorkTime';

export default function WeeklyForm({ isActive }) {

  const { defaultTime } = useContext(TaskContext);
  const { weeklyData, setWeeklyData } = useContext(TimeContext);

  const editDay = (dayIndex, timeIndex, field, value) => {
    setWeeklyData((prevWeeklyData) => {
      const updatedDays = [...prevWeeklyData];

      // Проверка существования дня
      if (dayIndex >= 0 && dayIndex < updatedDays.length) {
        const day = updatedDays[dayIndex];
        const updatedTimes = [...day.times];

        // Проверка существования времени
        if (timeIndex >= 0 && timeIndex < updatedTimes.length) {
          updatedTimes[timeIndex] = {
            ...updatedTimes[timeIndex],
            [field]: value,
          };
          updatedDays[dayIndex] = {
            ...day,
            times: updatedTimes,
          };
        }
      }

      return updatedDays;
    });
  };

  const addTimeToRange = (dayIndex) => {
    setWeeklyData((prevWeeklyData) => {
      const updatedDays = [...prevWeeklyData];

      // Проверяем, существует ли день с указанным индексом
      if (dayIndex >= 0 && dayIndex < updatedDays.length) {
        const day = updatedDays[dayIndex];
        const updatedTimes = [...day.times];

        // Добавляем новый временной интервал, используя текущие значения по умолчанию
        updatedTimes.push(defaultTime());

        // Обновляем день с новым списком временных интервалов
        updatedDays[dayIndex] = {
          ...day,
          times: updatedTimes,
        };
      }

      return updatedDays;
    });
  };

  const removeTimeFromRange = (dayIndex, timeIndex) => {
    setWeeklyData((prevWeeklyData) => {
      const updatedDays = [...prevWeeklyData];

      // Проверяем, существует ли день и временной интервал с указанными индексами
      if (dayIndex >= 0 && dayIndex < updatedDays.length) {
        const day = updatedDays[dayIndex];
        const updatedTimes = [...day.times];

        // Проверяем, существует ли временной интервал с указанным индексом
        if (timeIndex >= 0 && timeIndex < updatedTimes.length) {
          // Удаляем временной интервал по индексу
          updatedTimes.splice(timeIndex, 1);

          // Обновляем день с новым списком временных интервалов
          updatedDays[dayIndex] = {
            ...day,
            times: updatedTimes,
          };
        }
      }

      return updatedDays;
    });
  };

  const activateDay = (dayIndex) => {
    setWeeklyData((prevWeeklyData) => {
      const updatedDays = [...prevWeeklyData];

      // Проверяем, существует ли день с указанным индексом
      if (dayIndex >= 0 && dayIndex < updatedDays.length) {
        const day = updatedDays[dayIndex];

        // Если день активируется впервые, добавляем временной интервал
        if (!day.isActive) {
          updatedDays[dayIndex] = {
            ...day,
            isActive: true,
            times: day.times.length === 0 ? [defaultTime()] : day.times, // Добавляем интервал только если их нет
          };
        } else {
          // Если день деактивируется, просто переключаем состояние
          updatedDays[dayIndex] = {
            ...day,
            isActive: false,
          };
        }
      }

      return updatedDays;
    });
  };


  return (
    <div className={`calendar-weekly ${isActive ? '' : 'inactive'}`}>
      <div className='calendar-weekly-container'>
        <div className='t-14' style={{ fontWeight: 400, textAlign: 'left' }}>Выполнять задачу каждый:</div>
        <div className='calendar-weekly-days-container'>
          {weeklyData.map((day, index) => (
            <button
              className={`t-14 ${weeklyData[index].isActive ? 'active' : ''}`}
              style={{ fontWeight: 400 }}
              key={index}
              onClick={() => activateDay(index)}  // Используем функцию activateDay
            >
              {day.shortName}
            </button>

          ))}
        </div>
      </div>
      <div
        className='working-hours-container'
      >
        {weeklyData.map(
          (dayValue, dayIndex) => (
            dayValue.isActive &&
            dayValue.times.map(
              (timeValue, timeIndex) => (
                <WorkTime
                  key={`${dayIndex}-${timeIndex}`}
                  day={dayValue.fullName}
                  dayIndex={dayIndex}
                  time={timeValue}
                  timeIndex={timeIndex}
                  addTimeToRange={addTimeToRange}
                  removeTimeFromRange={removeTimeFromRange}
                  editor={editDay}
                />
              )
            )
          )
        )
        }
      </div>
    </div>
  );
}
