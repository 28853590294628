import React, {useContext} from 'react'
import { ThemeContext } from '@context/ThemeContext';
import { AuthContext } from '@context/AuthContext';
import Mobile from '@components/Header/Header.Mobile';
import Registration from '@components/authentification/Registration/Registration';
import Login from '@components/authentification/Login';
import ResetPassword from '@components/authentification/ResetPassword';
import { Link } from 'react-router-dom';
import './Header.css'

import { HandySvg } from 'handy-svg';
import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/home', false, /\.(svg)$/));
const imagesPng = ImageUtils.importAllImages(require.context('@assets/home/images', false, /\.(png)$/));

export default function Header() {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const { activeAuthMenu, handleLogin, handleRegistration } = useContext(AuthContext);

  const NavigationBar = () => {
    return (
      <div className='home-header-nav-bar'>
        <h1 className="logo-toup-container">
          T
          <HandySvg src={images[`logo.svg`]} className='logo-toup' />
          UP
        </h1>
        <div className='home-header-links-container'>
          <Link to="/" className="home-header-link">
            Главная
          </Link>
          <Link to="/about" className="home-header-link">
            О нас
          </Link>
          <a href="https://t.me/toupSupport_bot" target="_blank" rel="noopener noreferrer" className="home-header-link">
            Поддержка
          </a>
          {/* <a href="#" className="home-header-link">Соц. Сети</a> */}
          <Link to="/partnership" className='home-header-link'>
            Партнерство
          </Link>
        </div>
        <div className='home-header-auth-buttons'>
          <img src={images[`${theme}Theme.svg`]} onClick={toggleTheme} alt="logo" className='mr-5' />
          <div className="home-login-button" onClick={handleLogin}>Войти</div>
          <div className="home-register-button" onClick={handleRegistration}>Регистрация</div>
        </div>
        <Mobile isHome={true} />
      </div>
    )
  };

  const WelcomeInfo = () => {
    return (
      <div className='home-header-welcome-info'>
        <div className='home-header-welcome-info-text'>
          <div className='welcome'>Добро пожаловать</div>
          <span className='label'>
            Быстрая раскрутка канала с T
            <HandySvg src={images[`logo.svg`]} className='logo-toup-label' />
            UP
          </span>
          <div className='text'>
            Мы предлагаем надежную накрутку подписчиков, просмотров и реакций, чтобы ваша группа стала еще более популярной и востребованной. Присоединяйтесь к нам и увидите, как ваша группа достигает новых высот!
          </div>
          <div className='buttons'>
            <div className='home-register-button' onClick={handleRegistration}>Начать сейчас</div>
            <a className='home-login-button' href="https://t.me/toupSupport_bot" target="_blank"
               rel="noopener noreferrer">
              Подробнее
            </a>
          </div>
          <div className='about-container'>
            <div className='about-item'>
              <div className='about-label'>2k+</div>
              <div className='about-description'>Довольных отзывов</div>
            </div>
            <div className='vertical-divider' />
            <div className='about-item'>
              <div className='about-label'>4.8</div>
              <div className='about-description'>Средняя оценка</div>
            </div>
            <div className='vertical-divider' />
            <div className='about-item'>
              <div className='about-label'>30k+</div>
              <div className='about-description'>Успешных задач</div>
            </div>
          </div>
        </div>
        <div className='home-header-welcome-info-img'>
          <img src={imagesPng[`welcome.png`]} alt={'welcome'}/>
        </div>
      </div>
    )
  };

  return (
    <div className='home-header-background'>
      <div className='home-header'>
        <NavigationBar />
        <WelcomeInfo />
        {activeAuthMenu === 'Registration' && <Registration />}
        {activeAuthMenu === 'Login' && <Login />}
        {activeAuthMenu === 'Reset' && <ResetPassword />}
      </div>
    </div>
  )
};
