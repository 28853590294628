import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from '@context/AuthContext';
import InputField from '@components/shared/InputField';
import LoginInfoBlock from './LoginInfoBlock';
import './ResetPassword.css'

import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/auth', false, /\.(svg)$/));

export default function ResetPassword() {
  const [email, setEmail] = useState('');
  const [stage, setStage] = useState('email');

  const { handleClose, handleLogin } = useContext(AuthContext);

  useEffect(() => {
    const handleMouseDown = (e) => {
      if (!e.target.closest('.auth-window') && !e.target.closest('.notification-window')) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [handleClose]);

  const EmailStage = () => {
    return (
      <div className='auth-form' style={{ gap: '5px' }}>
        <p className="text-blue-left">Забыли пароль?</p>
        <h2>Не волнуйтесь мы вам поможем</h2>
        <InputField
          label="Email"
          type="email"
          placeholder="Ваша почта"
          logo={images['mail.svg']}
          value={email}
          handleChange={(e) => setEmail(e.target.value)}
        />
        <button onClick={() => setStage('code')}>Отправить код</button>
        <button className='reset-back-btn' onClick={handleLogin}>Назад</button>
        <div className='steps-container'>
          <div className='step-item active' />
          <div className='step-item' />
          <div className='step-item' />
        </div>
      </div>
    )
  };

  const CodeStage = () => {
    return (
      <div className='auth-form' style={{ gap: '5px' }}>
        <p className="text-blue-left">Подтвердите код</p>
        <h2>Мы отправили код на вашу почту</h2>
        <div className='code-field-container'>
          <div className='code-field-item' />
          <div className='code-field-item' />
          <div className='code-field-item' />
          <div className='code-field-item' />
        </div>
        <span className="text-blue-left">
          Не пришел код?
          <span style={{ color: 'var(--text-color)' }}>
            examplelongpost@gmail.com
          </span>
        </span>
        <button onClick={() => setStage('password')}>Отправить код</button>
        <button className='reset-back-btn' onClick={() => setStage('email')}>Назад</button>
        <div className='steps-container'>
          <div className='step-item' />
          <div className='step-item active' />
          <div className='step-item' />
        </div>
      </div>
    )
  };

  const PasswordStage = () => {
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');

    return (
      <div className='auth-form' style={{ gap: '5px' }}>
        <p className="text-blue-left">Изменение пароля</p>
        <h2>Пожалуйста, придумайте новый пароль</h2>
        <InputField
          label="Новый пароль"
          type="password"
          placeholder="Введите пароль"
          logo={images['password.svg']}
          value={password1}
          handleChange={(e) => setPassword1(e.target.value)}
        />
        <InputField
          label="Повторите пароль"
          type="password"
          placeholder="Введите пароль"
          logo={images['password.svg']}
          value={password2}
          handleChange={(e) => setPassword2(e.target.value)}
        />
        <button onClick={handleLogin}>Изменить пароль</button>
        <div className='steps-container'>
          <div className='step-item' />
          <div className='step-item' />
          <div className='step-item active' />
        </div>
      </div>
    )
  };

  return (
    <div className="auth-window-overlay">
      <div className="auth-window">
        <div className="auth-content">
          {stage === 'email' ?
            <EmailStage /> :
            stage === 'code' ?
              <CodeStage /> :
              <PasswordStage />
          }
          <LoginInfoBlock />
        </div>
      </div>
    </div>
  )
}
